<template>
  <b-modal
    id="remove-payment-modal"
    ref="remove-payment-modal"
    title="ยืนยันลบประวัติบันทึกการชำระ"
    hide-footer
    centered
  >
    <h5>
      การลบประวัติการบันทึกยอด จะส่งผลให้ยอดค้างชำระของรายการนี้เพิ่มขึ้นตามจำนวน ยอดบันทึกที่โดนลบออกไป ตรวจสอบข้อมูลเพิ่มเติมด้านล่าง
    </h5>
    <br>
    <b-card
      border-variant="secondary"
      align="center"
      class="m-0"
    >
      <b-card-text>
        <b-form-group
          label="ยอดค้างชำระปัจจุบัน (บาท)"
          label-for="ยอดค้างชำระปัจจุบัน (บาท)"
          label-cols="6"
          class="p-0 m-0"
        >
          <b-form-input
            id="ยอดค้างชำระปัจจุบัน (บาท)"
            :value="totalAmount | currency"
            class="text-white text-right"
            style="font-weight: 600;"
            plaintext
          />
        </b-form-group>
      </b-card-text>
    </b-card>

    <div class="d-flex justify-content-center">
      <feather-icon
        size="32"
        icon="ArrowDownIcon"
        class="text-info"
      />
    </div>

    <b-card
      border-variant="secondary"
      align="center"
    >
      <b-card-text>
        <b-form-group
          label="ยอดค้างชำระหลังลบ (บาท)"
          label-for="ยอดค้างชำระหลังลบ (บาท)"
          label-cols="6"
          class="p-0 m-0"
        >
          <b-form-input
            id="ยอดค้างชำระหลังลบ (บาท)"
            :value="afterPayAmount | currency"
            class="text-danger text-right"
            style="font-weight: 600;"
            plaintext
          />
        </b-form-group>
      </b-card-text>
    </b-card>

    <br>
    <div class="d-flex justify-content-center">
      <b-button
        variant="outline-primary"
        class="mr-1"
        @click="hideModal"
      >
        ยกเลิก
      </b-button>
      <b-overlay :show="isLoading">
        <b-button
          variant="danger"
          @click="onSubmit"
        >
          ลบ
        </b-button>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SubmitPaymentModal',
  props: {
    payment: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      // validations

      file: null,

    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.payment.isRemovingPayment,
      isSuccess: (state) => state.payment.isRemovingPaymentSuccess,
    }),
    totalAmount() {
      return this.payment?.totalAmount || 0
    },
    afterPayAmount() {
      return this.payment?.afterPayAmount || 0
    },
  },
  watch: {
    isSuccess(val) {
      if (val) {
        this.hideModal()
        this.$emit('refetch')
      }
    },
  },
  methods: {
    ...mapActions(['removePayment']),
    onSubmit() {
      if(this.payment.id) {
        this.removePayment(this.payment.id)
      }
    },
    hideModal() {
      this.$bvModal.hide('remove-payment-modal')
    },
  },
  
}
</script>


<style lang="scss">

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'เลือกไฟล์';
}
</style>
